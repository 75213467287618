.device-info {
    font-family: inherit;
    position: relative;
    padding: 10px;
    width: auto;
    height: auto;
    display: block;
    margin: 10px auto;
    overflow: hidden;
    text-align: left;
}

.device-info ul {
    width: 100%;
    display: block;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.device-info li {
    width: 100%;
    display: inline-block;
    padding: 0px;
    margin: 0px;
}

.device-info li.label {
    font-size: 12px;
    font-weight: 900;
}

.device-info li.value {
    font-size: 14px;
    font-weight: 500;
}

.device-info li:nth-child(even) {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(181,181,181,1);
}

.device-info button {
    font-family: inherit;
    position: relative;
    padding: 10px;
    margin: 0;
    width: inherit;
    height: 100%;
    outline: none;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    -webkit-appearance: none;
}