.register-form {
    position: relative;
    padding: 0px;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: Verdana, sans-serif;
}

.register-form {
    display: block;
    width: 100%;
    height: 100%;
}

.register-form .input-wrapper {
    display: inline-block;
    width: 100%;
    height: 100%;
    margin: 10px auto 0px;
}

.register-form .input-wrapper input,
.register-form .input-wrapper button {
    font-family: inherit;
    position: relative;
    padding: 10px;
    margin: 0;
    width: inherit;
    height: 100%;
    outline: none;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    -webkit-appearance: none;
    z-index: 1;
}

.register-form .input-wrapper input.error {
    border-color: rgba(255, 0, 0, 1);
}

.register-form .input-wrapper label {
    display: none;
    /* display: inline-block; */
    position: absolute;
    top: -18px;
    left: 10px;
    padding: 10px 10px 0;
    margin: 0;
    width: auto;
    height: auto;
    font-size: 14px;
    background-color: rgba(255,255,255,1);
}

.register-form .input-wrapper span.note {
    font-family: inherit;
    font-size: 12px;
    color: rgba(111,111,111,1);
    line-height: inherit;
    display: inline-block;
    margin: 5px auto;
}

.register-form .input-wrapper.note-wrapper {
    text-align: center;
    font-family: inherit;
    font-size: 12px;
    color: #6f6f6f;
    line-height: inherit;
    display: block;
    margin: 20px auto;
}

.register-form .input-wrapper.note-wrapper .note {
    font-family: inherit;
    font-size: 12px;
    color: rgba(111,111,111,1);
    line-height: inherit;
    display: inline;
}

.register-form .material-symbols-rounded {
    position: absolute;
    color: rgba(111,111,111,1);
    background: white;
    right: 0;
    padding: 6px;
    margin: 2px;
    z-index: 2;
}