.account-page {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 70px 10px 10px;
}

.account-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}

.account-title-header {
    font-size: 16px;
    margin: 0 0 20px 0;
}

.account-sub-title-header {
    font-size: 16px;
}