/*BEGIN ITEM NAVIGATION*/
.reaction-navigation {
  height: 65px;
  width: 100%;
  display: block;
  background: #ffffffff;
  position: fixed;
  bottom: 0px;
  z-index: 1000;
}

.reaction-navigation-grid {
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}

.reaction-navigation-grid > div {
  text-align: center;
  font-size: 30px;
  line-height: 65px;
  background-color: #000;
}

.reaction-navigation-grid .reaction-navigation-icon.reaction-icon-plus {
  padding: 16px 0px;
  background-color: #B8FF3C;
  border-radius: 15px;
  line-height: 0px;
  text-shadow: none;
  margin: 15px auto;
  width: 50px;
  font-size: 30px;
  color: #000000;
  font-weight: 300;
}
/*END ITEM NAVIGATION*/