.app-home {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 0;
}

header {
    background-color: #333333;
    padding: 20px;
    color: #ffffff;
    text-align: center;
}

header h1 {
    font-size: 24px;
    margin: 0;
    }

nav {
    margin-top: 10px;
}

nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
}

nav ul li {
    margin: 0 10px;
}

nav ul li a {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
}

/* CSS for the section banner */
.section-banner {
    width: 100%;
    max-width: 100%;
    height: auto;
}

/* CSS for the footer */
footer {
    background-color: #333333;
    color: #ffffff;
    padding: 20px;
    text-align: center;
    font-size: 14px;
}

footer a {
    color: #ffffff;
    text-decoration: none;
}

/* CSS for the hero */
.hero {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
}

.hero-section {
    flex: 1;
    padding: 20px;
}

.hero-section h2 {
    font-size: 32px;
    margin-bottom: 16px;
}

.hero-section p {
    font-size: 18px;
    margin-bottom: 24px;
}

.hero-section a {
    display: inline-block;
    padding: 12px 24px;
    background-color: #ffffff;
    color: #000000;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
    }

/* CSS for mobile layout */
@media (max-width: 768px) {
    .hero {
        height: 100%;
    }

    .hero-section {
        flex-basis: 100%;
        margin-bottom: 20px; /* Add margin-bottom to create space between sections on mobile */
    }

    footer {
        flex-basis: 100%;
    }
}