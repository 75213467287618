/* .thumbnailListContainer {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
} */

.thumbnailListContainer {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  z-index: 500;
  position: absolute;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-padding: 1px;
  width: 100%;
  margin: 10px;
  box-sizing: border-box;
}

/* .videoThumbnail {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin-right: 20px;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
} */

.videoThumbnail {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin-right: 20px;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
  display: inline-block;
  scroll-snap-align: start;
}

.videoThumbnail video {
  width: 100%;
  height: auto;
  object-fit: cover;
}
