.process-message-modal {
    font-family: inherit;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.process-message-modal .process-message-content-wrapper {
    font-family: inherit;
    background-color: rgba(255,255,255,0);
    padding: 2em;
}

.process-message-modal .process-message-content-wrapper p {
    font-family: inherit;
    color: rgba(255,255,255,1);
    font-weight: 700;
}