body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-home {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 0;
}

header {
    background-color: #333333;
    padding: 20px;
    color: #ffffff;
    text-align: center;
}

header h1 {
    font-size: 24px;
    margin: 0;
    }

nav {
    margin-top: 10px;
}

nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
}

nav ul li {
    margin: 0 10px;
}

nav ul li a {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
}

/* CSS for the section banner */
.section-banner {
    width: 100%;
    max-width: 100%;
    height: auto;
}

/* CSS for the footer */
footer {
    background-color: #333333;
    color: #ffffff;
    padding: 20px;
    text-align: center;
    font-size: 14px;
}

footer a {
    color: #ffffff;
    text-decoration: none;
}

/* CSS for the hero */
.hero {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
}

.hero-section {
    flex: 1 1;
    padding: 20px;
}

.hero-section h2 {
    font-size: 32px;
    margin-bottom: 16px;
}

.hero-section p {
    font-size: 18px;
    margin-bottom: 24px;
}

.hero-section a {
    display: inline-block;
    padding: 12px 24px;
    background-color: #ffffff;
    color: #000000;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
    }

/* CSS for mobile layout */
@media (max-width: 768px) {
    .hero {
        height: 100%;
    }

    .hero-section {
        flex-basis: 100%;
        margin-bottom: 20px; /* Add margin-bottom to create space between sections on mobile */
    }

    footer {
        flex-basis: 100%;
    }
}
.search-form {
    position: relative;
    padding: 0px;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: Verdana, sans-serif;
}

.search-form {
    display: block;
    width: 100%;
    height: 100%;
}

.search-form .input-wrapper {
    display: inline-block;
    width: 100%;
    height: 100%;
    margin: 10px auto 0px;
}

.search-form .input-wrapper input {
    font-family: inherit;
    position: relative;
    padding: 10px;
    margin: 0;
    width: inherit;
    height: 100%;
    outline: none;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    -webkit-appearance: none;
}

.search-form .input-wrapper input.error {
    border-color: rgba(255, 0, 0, 1);
}

.search-form .input-wrapper label {
    display: none;
    /* display: inline-block; */
    position: absolute;
    top: -18px;
    left: 10px;
    padding: 10px 10px 0;
    margin: 0;
    width: auto;
    height: auto;
    font-size: 14px;
    background-color: rgba(255,255,255,1);
}


.list-item {
    font-family: inherit;
    text-align: left;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 20px 0px;
}

.list-item .image-wrapper {
    background-color: rgba(181,181,181,1);
    position: absolute;
    margin: 0 20px 20px 0;
    height: 60px;
    width: 60px;
    overflow: hidden;
}

.list-item img {
    position: relative;
    display: inline-block;
    margin: 0;
    width: inherit;
    height: auto;
}

.list-item .header-wrapper {
    position: relative;
    margin: 0 0 20px 80px;
    height: 60px;
    overflow: hidden;
    width: auto;
}

.list-item .header-wrapper .item-title {
    font-family: inherit;
    margin: 0px;
    font-size: 1.20em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-item .header-wrapper .item-sub-text {
    color: rgba(111, 111, 111, 1);
    font-family: inherit;
    font-size: 14px;
    line-height: 20px;
    font-style: italic;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-item p {
    font-family: inherit;
    line-height: 24px;
    padding: 0px;
    margin-bottom: 20px;
    clear: both;
}

.list-item .item-link {
    color: rgba(1,118,152,1);
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    position: relative;
    font-weight: bold;
    float: right;
    text-decoration: none;
}
.lists {
    position: relative;
    display: block;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.lists ul {
    width: 100%;
    display: block;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.lists li {
    width: 100%;
    display: inline-block;
    padding: 0px;
    margin: 0px;
    border-top: 1px solid rgba(181,181,181,1);
}
.pagination {
    font-family: inherit;
    position: relative;
    display: block;
    margin: 0;
    padding: 0px;
    text-align: center;
}

.pagination-header {
    color: rgba(50, 50, 50, 1);
    font-size: 14px;
    position: relative; 
    margin: 20px 20px;
}

.pagination-text {
    color: rgba(50, 50, 50, 1);
    font-size: 12px;
    position: relative; 
}

.pagination-item-link {
    /* color: rgba(50, 50, 50, 1);
    font-size: 12px;
    font-family: inherit;
    position: relative;
    text-decoration: none;
    display: inline-block;
    margin: 10px 3px; */

    color: rgba(50, 50, 50, 1);
    font-size: 12px;
    font-family: inherit;
    text-decoration: none;
    display: inline-block;
    margin: 10px 0px;
    border: 1px solid rgba(181,181,181,1);
    padding: 12px;
    margin-left: -1px;
}

.pagination-item-link.back, 
.pagination-item-link.forward {
    /* font-size: 12px;
    margin: 10px 3px; */

    font-size: 12px;
    margin: 10px 0px;
}

.pagination-item-link.forward {
    margin-left: -1px;
}

.pagination-item-link.active {
    font-weight: bold;
}

.pagination-item-link:active,
.pagination-item-link:focus
.pagination-item-link:hover {
    color: rgba(50, 50, 50, 1);
}
.search-page {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 70px 10px 10px;
}

.search-wrapper,
.search-lists-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}
.page-not-found {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 70px 10px 10px;
}

.page-not-found-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}
.server-error {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 70px 10px 10px;
}

.server-error-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}
.item {
    font-family: inherit;
    text-align: left;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 20px 0px;
}

.item .image-wrapper {
    background-color: rgba(181,181,181,1);
    position: absolute;
    margin: 0 20px 20px 0;
    height: 60px;
    width: 60px;
    overflow: hidden;
}

.item .image-wrapper img {
    position: relative;
    display: inline-block;
    margin: 0;
    width: inherit;
    height: auto;
}

.item .header-wrapper {
    position: relative;
    margin: 0 0 20px 80px;
    height: 60px;
    overflow: hidden;
    width: auto;
}

.item .header-wrapper .item-title {
    font-family: inherit;
    margin: 0px;
    font-size: 1.20em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.item .item-sub-text {
    font-family: inherit;
    color: rgba(181, 181, 181, 1);
    font-size: 14px;
    line-height: 20px;
    font-style: italic;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.item p {
    font-family: inherit;
    line-height: 24px;
    padding: 0px;
    margin-bottom: 20px;
    clear: both;
}

.item .item-link {
    color: rgba(1,118,152,1);
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    position: relative;
    font-weight: bold;
    float: right;
    text-decoration: none;
    background: none;
    border: none;
}

.item-actions {
    position: relative;
    display: flex;
}

.item-actions button {
    position: relative;
    color: rgba(1,118,152,1);
    display: inline-flex;
    width: auto;
    background: none;
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
}

.item-actions button.paid,
.item-actions button.liked {
    color: rgba(1,118,152,1);
}

.item-actions .like-button .text-value {
    padding: 2px 7px;
}

.item-actions .paid-button .text-value {
    padding: 2px 0px;
}
.back-button {
    font-family: inherit;
    display: inline-block;
}


.item-page {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 70px 10px 10px;
}

.item-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}
.login-form {
    position: relative;
    padding: 0px;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: Verdana, sans-serif;
}

.login-form {
    display: block;
    width: 100%;
    height: 100%;
}

.login-form .input-wrapper {
    display: inline-block;
    width: 100%;
    height: 100%;
    margin: 10px auto 0px;
}

.login-form .input-wrapper input,
.login-form .input-wrapper button {
    font-family: inherit;
    position: relative;
    padding: 10px;
    margin: 0;
    width: inherit;
    height: 100%;
    outline: none;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}

.login-form .input-wrapper input.error {
    border-color: rgba(255, 0, 0, 1);
}

.login-form .input-wrapper label {
    display: none;
    /* display: inline-block; */
    position: absolute;
    top: -18px;
    left: 10px;
    padding: 10px 10px 0;
    margin: 0;
    width: auto;
    height: auto;
    font-size: 14px;
    background-color: rgba(255,255,255,1);
}

.login-form .input-wrapper.note-wrapper {
    text-align: center;
    font-family: inherit;
    font-size: 12px;
    color: #6f6f6f;
    line-height: inherit;
    display: block;
    width: 100%;
    margin: 20px auto;
}

.login-form .input-wrapper.note-wrapper .note {
    font-family: inherit;
    font-size: 12px;
    color: rgba(111,111,111,1);
    line-height: inherit;
    display: inline;
}

.login-form .material-symbols-rounded {
    position: absolute;
    color: rgba(111,111,111,1);
    background: white;
    right: 0;
    padding: 6px;
    margin: 2px;
    z-index: 2;
}

.login-page {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 70px 10px 10px;
}

.login-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}
.password-reset-form {
    position: relative;
    padding: 0px;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: Verdana, sans-serif;
}

.password-reset-form {
    display: block;
    width: 100%;
    height: 100%;
}

.password-reset-form .input-wrapper {
    display: inline-block;
    width: 100%;
    height: 100%;
    margin: 10px auto 0px;
}

.password-reset-form .input-wrapper input,
.password-reset-form .input-wrapper button {
    font-family: inherit;
    position: relative;
    padding: 10px;
    margin: 0;
    width: inherit;
    height: 100%;
    outline: none;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    -webkit-appearance: none;
    z-index: 1;
}

.password-reset-form .input-wrapper input.error {
    border-color: rgba(255, 0, 0, 1);
}

.password-reset-form .input-wrapper label {
    display: none;
    /* display: inline-block; */
    position: absolute;
    top: -18px;
    left: 10px;
    padding: 10px 10px 0;
    margin: 0;
    width: auto;
    height: auto;
    font-size: 14px;
    background-color: rgba(255,255,255,1);
}

.password-reset-form .input-wrapper span.note {
    font-family: inherit;
    font-size: 12px;
    color: rgba(111,111,111,1);
    line-height: inherit;
    text-align: left;
    display: inline-block;
    margin: 5px auto;
}

.password-reset-form .material-symbols-rounded {
    position: absolute;
    color: rgba(111,111,111,1);
    background: white;
    right: 0;
    padding: 6px;
    margin: 2px;
    z-index: 2;
}

.verification-modal {
    font-family: inherit;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.verification-modal .verification-content-wrapper {
    font-family: inherit;
    width: 70vw;
    padding: 20px;
    background-color: rgba(255,255,255,1);
    text-align: center;
}

.verification-modal .verification-content-wrapper p {
    font-family: inherit;
    font-weight: 700;
    margin: 0;
}

.verification-modal .verification-content-wrapper span {
    font-family: inherit;
    font-weight: 700;
    font-size: 12px;
}

.verification-modal .verification-content-wrapper .code-input {
    display: flex;
    width: inherit;
    overflow: hidden;
    height: 50px;
    margin: 10px auto;
}

.verification-modal .input-wrapper {
    display: inline-flex;
    width: 100%;
}

.verification-modal .input-wrapper input,
.verification-modal .input-wrapper button {
    font-family: inherit;
    position: relative;
    padding: 5px 10px;
    margin: 2px;
    width: auto;
    height: auto;
    outline: none;
    border: 1px solid #ccc;
    box-sizing: border-box;
    -webkit-appearance: none;
    text-align: center;
    text-transform: uppercase;
}

.verification-modal input[type="button"],
.verification-modal input[type="submit"] {
    margin: auto 10px;
    width: auto;
    padding: 10px 20px;
    display: inline-block;
}

.verification-modal .input-wrapper input.error {
    border-color: rgba(255, 0, 0, 1);
}

@media screen and (min-width: 1024px) {
    .verification-modal .verification-content-wrapper {
        font-family: inherit;
        width: 30vw;
        padding: 20px;
        background-color: rgba(255,255,255,1);
        text-align: center;
    }
}
  
.forgot-password-page {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 70px 10px 10px;
}

.forgot-password-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}
.register-form {
    position: relative;
    padding: 0px;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: Verdana, sans-serif;
}

.register-form {
    display: block;
    width: 100%;
    height: 100%;
}

.register-form .input-wrapper {
    display: inline-block;
    width: 100%;
    height: 100%;
    margin: 10px auto 0px;
}

.register-form .input-wrapper input,
.register-form .input-wrapper button {
    font-family: inherit;
    position: relative;
    padding: 10px;
    margin: 0;
    width: inherit;
    height: 100%;
    outline: none;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    -webkit-appearance: none;
    z-index: 1;
}

.register-form .input-wrapper input.error {
    border-color: rgba(255, 0, 0, 1);
}

.register-form .input-wrapper label {
    display: none;
    /* display: inline-block; */
    position: absolute;
    top: -18px;
    left: 10px;
    padding: 10px 10px 0;
    margin: 0;
    width: auto;
    height: auto;
    font-size: 14px;
    background-color: rgba(255,255,255,1);
}

.register-form .input-wrapper span.note {
    font-family: inherit;
    font-size: 12px;
    color: rgba(111,111,111,1);
    line-height: inherit;
    display: inline-block;
    margin: 5px auto;
}

.register-form .input-wrapper.note-wrapper {
    text-align: center;
    font-family: inherit;
    font-size: 12px;
    color: #6f6f6f;
    line-height: inherit;
    display: block;
    margin: 20px auto;
}

.register-form .input-wrapper.note-wrapper .note {
    font-family: inherit;
    font-size: 12px;
    color: rgba(111,111,111,1);
    line-height: inherit;
    display: inline;
}

.register-form .material-symbols-rounded {
    position: absolute;
    color: rgba(111,111,111,1);
    background: white;
    right: 0;
    padding: 6px;
    margin: 2px;
    z-index: 2;
}
.registration-page {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 70px 10px 10px;
}

.registration-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}
.account-page {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 70px 10px 10px;
}

.account-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}

.account-title-header {
    font-size: 16px;
    margin: 0 0 20px 0;
}

.account-sub-title-header {
    font-size: 16px;
}
.account-info {
    font-family: inherit;
    position: relative;
    width: 100%;
    height: auto;
    display: block;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    text-align: left;
}

.account-info ul {
    width: 100%;
    display: block;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.account-info li {
    width: 100%;
    display: inline-block;
    padding: 0px;
    margin: 0px;
}

.account-info li.label {
    font-size: 12px;
    font-weight: 900;
    margin-bottom: 10px;
}

.account-info li.value {
    font-size: 14px;
    font-weight: 500;
}

.account-info li:nth-child(even) {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(181,181,181,1);
}

.account-info .input-action-wrapper {
    text-align: center;
    position: relative;    
    display: block;
    margin: 10px auto;
    background: transparent;
    width: 100%;
    height: auto;
    z-index: 1;
}

.account-info input,
.account-info button,
.account-info select,
.account-info option {
    font-family: inherit;
    position: relative;
    padding: 10px;
    margin: 0;
    width: inherit;
    height: 100%;
    outline: none;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    -webkit-appearance: none;
}

.account-info input[type="button"],
.account-info input[type="submit"] {
    margin: auto 10px;
    border-radius: 20px;
    width: auto;
    padding: 10px 20px;
    display: inline-block;
}

.account-info input.error {
    border-color: rgba(255, 0, 0, 1);
}
.device-info:nth-child(odd) {
    background-color: rgba(180, 180, 180, 0.5);
}
.device-info {
    font-family: inherit;
    position: relative;
    padding: 10px;
    width: auto;
    height: auto;
    display: block;
    margin: 10px auto;
    overflow: hidden;
    text-align: left;
}

.device-info ul {
    width: 100%;
    display: block;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.device-info li {
    width: 100%;
    display: inline-block;
    padding: 0px;
    margin: 0px;
}

.device-info li.label {
    font-size: 12px;
    font-weight: 900;
}

.device-info li.value {
    font-size: 14px;
    font-weight: 500;
}

.device-info li:nth-child(even) {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(181,181,181,1);
}

.device-info button {
    font-family: inherit;
    position: relative;
    padding: 10px;
    margin: 0;
    width: inherit;
    height: 100%;
    outline: none;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    -webkit-appearance: none;
}
.confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirmation-modal .content-wrapper {
    background-color: rgba(255,255,255,1.0);
    padding: 2em;
    text-align: center;
}

.confirmation-modal .content-wrapper button {
    font-family: inherit;
    position: relative;
    padding: 10px;
    margin: auto 10px;
    width: inherit;
    height: 100%;
    outline: none;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    -webkit-appearance: none;
}
.about-us-page {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 70px 10px 10px;
}

.about-us-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}
.privacy-page {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 70px 10px 10px;
}

.privacy-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}
.terms-page {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 70px 10px 10px;
}

.terms-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}
.faqs-page {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 70px 10px 10px;
}

.faqs-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}
.vidoes {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
}
.videoContainer {
  position: relative; /* Change this from 'fixed' */
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
  overflow: hidden;
}

.responsiveVideo {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute; /* Change this from 'fixed' */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.videoImage {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  z-index: 1;
}

/* .videoImage.facingMode {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
} */

.videoImageBackground { 
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);  
  background-color: rgba(0,0,0,1);
  pointer-events: none;
  z-index: 0;
}

/* .videoImageBackground.facingMode {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
} */
/* .thumbnailListContainer {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
} */

.thumbnailListContainer {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  z-index: 500;
  position: absolute;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-padding: 1px;
  width: 100%;
  margin: 10px;
  box-sizing: border-box;
}

/* .videoThumbnail {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin-right: 20px;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
} */

.videoThumbnail {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin-right: 20px;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
  display: inline-block;
  scroll-snap-align: start;
}

.videoThumbnail video {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.videoListContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-padding: 1px; /* Prevents snapping on iOS devices */
}
html  {
    background: #ffffff;
    scroll-snap-type: y mandatory;
}

body {
    font-family: 'Roboto', sans-serif;
    position: relative;
    margin: 0;
    font-size: 16px;
    /* color: #fff;
    text-shadow: 1px 1px #000; */
}

.grid-container {
    position: relative;
    display: grid;
    grid-template-columns: auto;
    grid-gap: 0;
    gap: 0;
    background-color: #ffffff;
    height: calc(100vh - 65px);
    padding: 0;
    margin: 0;
    scroll-snap-align: start;
    overflow: hidden;
    z-index: 1;
  }
  
  .grid-container > div.grid-item {
    position: relative;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 0;
    font-size: 30px;
    background: linear-gradient(45deg, #808080, #a0a0a0, #d0d0d0); /* dark gray to medium gray to light gray */
    background-size: 200% 200%;
    animation: gradientAnimation 3s infinite;
  }

  .grid-container:last-child {
    margin-bottom: 65px;
  }
  
  .grid-item.right-box-2x4 {
    grid-area: 1 / 2 / 3 / 4;
  }

  .grid-item.left-box-2x4 {
    grid-area: 4 / 1 / 6 / 3;
  }

  .grid-container > div.grid-item-top {
    position: absolute;
    display: block;
    top: 0px;
    height: 65px;
    width: 100%;
    background: transparent;
    z-index: 300;
  }

  .grid-container > div.grid-item-left {
    position: absolute;
    display: block;
    padding: 0 0 0 15px;
    left: 0px;
    bottom: 100px;
    width: 110px;
    height: 140px;
    background: transparent;
    z-index: 200;
  }

  .grid-container > div.grid-item-right {
    position: absolute;
    display: block;
    right: 0px;
    bottom: 65px;
    width: 65px;
    background: transparent;
    z-index: 200;
  }

  .grid-container > div.grid-item-bottom {
    position: absolute;
    display: block;
    bottom: 0px;
    height: 65px;
    width: auto;
    padding: 15px;
    background: transparent;
    z-index: 100;
  }

  .grid-container > div.grid-item {
    position: absolute;
    display: block;
    top: 0px;
    bottom: 0px;
    width: 100%;
    background-color: #ffffff;
    z-index: 1;
  }

  @keyframes gradientAnimation {
    0% {
        background-position: 100% 0%;
    }
    50% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 100% 0%;
    }
  }
/*BEGIN ITEM VIDEO*/

.grid-item .item-video {
  width: 100%;
  height: 100%;
  position: relative;
}

/*END ITEM VIDEO*/

/*BEGIN VIDEO AND IMAGE VIDEO*/

.video {
  position: absolute;
  width: 100vw;
  height: auto;
  object-fit: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,1);
  pointer-events: none;
  z-index: 1;

  /* min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; */
}

.video-image {
  position: absolute;
  width: 100vw;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,1);
  pointer-events: none;
  z-index: 1;
}

.video-image-blur {
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  filter: blur(8px);
  -webkit-filter: blur(8px);  
  background-color: rgba(0,0,0,1);
  pointer-events: none;
  z-index: 0;
}
/*END VIDEO AND IMAGE VIDEO*/
/*BEGIN ITEM BUBBLE*/
.item-bubbles {
  padding: 15px 0 0;
  height: 140px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.item-bubble-box {
  display: inline-block;
  position: relative;
  width: auto;
  height: inherit;
  margin: auto 5px;
}

.item-bubble-box > div {
  float: left;
  clear: both;
  margin-bottom: 10px;
}

.item-bubble {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  line-height: 100px;
  color: #ffffff;
  border-style: dashed;
  border-width: 2px;
  border-color: #ffffff;
  margin: auto;
  text-align: center;
  background-color: gray;
}

.item-bubble.item-bubble-30 {
  font-size: 6px;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  line-height: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border-width: 1px;
}

.item-bubble.item-bubble-45 {
  font-size: 9px;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  line-height: 45px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border-width: 1px;
}

.item-bubble.item-bubble-50 {
  font-size: 11px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  line-height: 50px;
  margin: 0 5px 10px;
  border-width: 1px;
}

.item-bubble.item-bubble-60 {
  font-size: 12px;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  line-height: 60px;
}

.item-bubble.item-bubble-100 {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  line-height: 100px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.item-bubble-live {
  display: inline;
  background-color: #B8FF3C;
  color: #000;
  text-shadow: none;
  border-radius: 5px;
  border: 1px solid #ffffff;
  
}

.item-bubble.item-bubble-30 .item-bubble-live {
  position: absolute;
  line-height: 10px;
  transform: translate(-53%, -50%);
  bottom: -15px;
  padding: 1px 3px;
}

.item-bubble.item-bubble-45 .item-bubble-live {
  position: absolute;
  line-height: 10px;
  transform: translate(-53%, -50%);
  bottom: -18px;
  padding: 3px;
} 

.item-bubble.item-bubble-50 .item-bubble-live {
  position: absolute;
  line-height: 10px;
  transform: translate(-125%, -50%);
  bottom: -5px;
  padding: 4px;
} 

.item-bubble.item-bubble-60 .item-bubble-live {
  position: absolute;
  line-height: 10px;
  transform: translate(-125%, -50%);
  bottom: -5px;
  padding: 5px;
}

.item-bubble.item-bubble-50 .item-bubble-live.top {
  position: absolute;
  line-height: 10px;
  transform: translate(-125%, -50%);
  bottom: 70px;
  padding: 4px;
} 

.item-bubble.item-bubble-60 .item-bubble-live.top {
  position: absolute;
  line-height: 10px;
  transform: translate(-125%, -50%);
  bottom: 58px;
  padding: 5px;
}

.item-bubble.item-bubble-100 .item-bubble-live {
  position: absolute;
  line-height: 10px;
  transform: translate(-53%, -50%);
  bottom: -28px;
  padding: 9px;
} 
/*END ITEM BUBBLE*/

/*BEGIN VIDEO AND IMAGE BUBBLE*/

.bubble-image,
.bubble-video {
  position: absolute;
  width: inherit;
  height: inherit;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  border-radius: inherit;
}

.item-bubble.item-bubble-50 .bubble-image,
.item-bubble.item-bubble-60 .bubble-image, 
.item-bubble.item-bubble-50 .bubble-video,
.item-bubble.item-bubble-60 .bubble-video {
  position: relative;
}
/*END VIDEO AND IMAGE BUBBLE*/
/*BEGIN ITEM ACTION*/
.item-action {
    position: relative;
    display: block;
    font-size: 13px;
    color: #ffffff;
    height: 50px;
    width: 50px;
    margin: 30px auto;
  }
  
  .item-icon {
    height: 38px;
    width: 50px;
    background: gray;
  }
  
  .item-text {
    height: 13px;
    width: 50px;
    text-align: center;
  }
  /*END ITEM ACTION*/
/*BEGIN ITEM CONTENT*/

.item-content {
    font-size: 13px;
    color: #fff;
    display: block;
    height: auto;
    position: relative;
    overflow: hidden;
  }
  
  .item-content .item-info {
    display: flex;
    position: relative;
    margin-bottom: 10px;
  }
  
  .item-content .item-info > div {
    line-height: 24px;
    margin-right: 5px;
  }
  
  .item-content .item-info .item-username {
    display: inline-flex;
    position: relative;
  }
  
  .item-content .item-info .item-avatar {
    display: inline-flex;
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: gray;
  }
  
  .item-content .item-info .item-follow {
    display: inline-flex;
    position: relative;
  }
  
  .item-content .item-description {
    display: block;
    min-height: 150px;
    height: auto;
    overflow: hidden;
  }
  /*END ITEM CONTENT*/
/*BEGIN REACTION CAMERA BUTTON*/
.reaction-camera-button {
  display: block;
  height: inherit;
  width: inherit;
  padding: 0;
  margin: 0;
  background: rgba(255, 255, 255, 0.2);
  border-style: dashed;
  border-width: 2px;
  border-radius: 5px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  text-shadow: none;
}

.reaction-camera-button .camera-icon {
  height: 40px;
  width: 40px;
  margin: 10px auto;
  background-color: rgba(255, 255, 255, 0.5);
}
/*END REACTION CAMERA BUTTON*/
/*BEGIN REACTION CAMERA BUTTON*/
.reaction-camera-view {
  display: block;
  height: inherit;
  width: inherit;
  padding: 0;
  margin: 0;
  background: rgba(255, 255, 255, 0.2);
  border-style: dashed;
  border-width: 2px;
  border-radius: 5px;
  text-align: center;
  padding: 0px;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  text-shadow: none;
  position: relative;
}

.reaction-camera-view .recording-wrapper-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 35px;
  width: 25px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  overflow: hidden;
  z-index: 5;
}

.reaction-camera-view .recording-wrapper-icon .recording-icon {
  height: 25px;
  width: 25px;
  margin: auto;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.5);
}

.reaction-camera-view .recording-wrapper-icon .recording-text {
  font-size: 10px;
  color: rgba(255, 255, 255, 1);
}
/*END REACTION CAMERA BUTTON*/
.camera-wrapper {
    /* position: relative; */
    display: table;
    height: inherit;
    z-index: 1
}

/* .canvas-video {
    max-width: 100%;
    height: inherit;
    display: table-cell;
    vertical-align: middle;
    position: absolute;
    z-index: 3
}

.canvas-video.facing-mode {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); 
    -moz-transform: rotateY(180deg); 
} */

.camera-video {
    max-width: 100%;
    height: inherit;
    display: table-cell;
    vertical-align: middle;
    position: absolute;
    z-index: 2 
}

.camera-video.facing-mode {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); 
    -moz-transform: rotateY(180deg); 
}


/*BEGIN ITEM NAVIGATION*/
.reaction-navigation {
  height: 65px;
  width: 100%;
  display: block;
  background: #ffffffff;
  position: fixed;
  bottom: 0px;
  z-index: 1000;
}

.reaction-navigation-grid {
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}

.reaction-navigation-grid > div {
  text-align: center;
  font-size: 30px;
  line-height: 65px;
  background-color: #000;
}

.reaction-navigation-grid .reaction-navigation-icon.reaction-icon-plus {
  padding: 16px 0px;
  background-color: #B8FF3C;
  border-radius: 15px;
  line-height: 0px;
  text-shadow: none;
  margin: 15px auto;
  width: 50px;
  font-size: 30px;
  color: #000000;
  font-weight: 300;
}
/*END ITEM NAVIGATION*/
.magic-card {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 70px 10px 10px;
}

.magic-card-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}

.magic-card-page h1 {
    text-align: center;
    margin: 10% auto;
}

.magic-card-page span {
    display: block;
    font-size: 0.6em;
    text-align: center;
}


@media (min-width: 1024px) {
    .magic-card-page h1 {
        text-align: center;
        margin: 20px auto;
    }
}
.card-deck {
    cursor: pointer;
}

.card-deck:after {
    content: "\1F0A0";
    font-size: 200px;
}

.card-deck.red:after {
    color: red;
}

.card-deck.blue:after {
    color: blue;
}

.card-deck.black:after {
    color: black;
}
.card.heart:after, 
.card.diamond:after {
  color: red;
}

.card.spade:after, 
.card.club:after {
  color: black;
}

.card.heart:after, 
.card.diamond:after,
.card.spade:after, 
.card.club:after {
  font-size: 200px;
}

/* cards  hearts */

.heart-a:after {
  content: "\1F0B1";
}

.heart-2:after {
  content: "\1F0B2";
}

.heart-3:after {
  content: "\1F0B3";
}

.heart-4:after {
  content: "\1F0B4";
}

.heart-5:after {
  content: "\1F0B5";
}

.heart-6:after {
  content: "\1F0B6";
}

.heart-7:after {
  content: "\1F0B7";
}

.heart-8:after {
  content: "\1F0B8";
}

.heart-9:after {
  content: "\1F0B9";
}

.heart-10:after {
  content: "\1F0BA";
}

.heart-j:after {
  content: "\1F0BB";
}

.heart-q:after {
  content: "\1F0BD";
}

.heart-k:after {
  content: "\1F0BE";
}

/* end cards hearts */

/* cards diamonds */

.diamond-a:after {
  content: "\1F0C1";
}

.diamond-2:after {
  content: "\1F0C2";
}

.diamond-3:after {
  content: "\1F0C3";
}

.diamond-4:after {
  content: "\1F0C4";
}

.diamond-5:after {
  content: "\1F0C5";
}

.diamond-6:after {
  content: "\1F0C6";
}

.diamond-7:after {
  content: "\1F0C7";
}

.diamond-8:after {
  content: "\1F0C8";
}

.diamond-9:after {
  content: "\1F0C9";
}

.diamond-10:after {
  content: "\1F0CA";
}

.diamond-j:after {
  content: "\1F0CB";
}

.diamond-q:after {
  content: "\1F0CD";
}

.diamond-k:after {
  content: "\1F0CE";
}

/* end cards diamonds */

/* cards spades */

.spade-a:after {
  content: "\1F0A1";
}

.spade-2:after {
  content: "\1F0A2";
}

.spade-3:after {
  content: "\1F0A3";
}

.spade-4:after {
  content: "\1F0A4";
}

.spade-5:after {
  content: "\1F0A5";
}

.spade-6:after {
  content: "\1F0A6";
}

.spade-7:after {
  content: "\1F0A7";
}

.spade-8:after {
  content: "\1F0A8";
}

.spade-9:after {
  content: "\1F0A9";
}

.spade-10:after {
  content: "\1F0AA";
}

.spade-j:after {
  content: "\1F0AB";
}

.spade-q:after {
  content: "\1F0AD";
}

.spade-k:after {
  content: "\1F0AE";
}

/* end cards spades */

/* cards clubs */

.club-a:after {
  content: "\1F0D1";
}

.club-2:after {
  content: "\1F0D2";
}

.club-3:after {
  content: "\1F0D3";
}

.club-4:after {
  content: "\1F0D4";
}

.club-5:after {
  content: "\1F0D5";
}

.club-6:after {
  content: "\1F0D6";
}

.club-7:after {
  content: "\1F0D7";
}

.club-8:after {
  content: "\1F0D8";
}

.club-9:after {
  content: "\1F0D9";
}

.club-10:after {
  content: "\1F0DA";
}

.club-j:after {
  content: "\1F0DB";
}

.club-q:after {
  content: "\1F0DD";
}

.club-k:after {
  content: "\1F0DE";
}

/* end cards clubs */
.question-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  height: auto;
}

.question-card {
  padding: 10%;
  text-align: center;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.question-card h2 {
  margin-bottom: 20px;
}

.box-action > div.card {
  margin: 10px;
  display: inline-block;
  cursor: pointer;
}

.box-action > div.card.heart:after  {
  content: "\2665";
  color: red;
}

.box-action > div.card.spade:after {
  content: "\2660";
  color: black;
}

.box-action > div.card.diamond:after {
  content: "\2666";
  color: red;
}

.box-action > div.card.club:after {
  content: "\2663";
  color: black;
}

.box-action > div.card:after {
  font-size: 100px;
}

.question-card > div.card.joker:before {
  content: "\1F0DF";
  color: blue;
  font-size: 150px;
  display: block;
}
 
/* .overlay-wrapper div.card.joker:before {
  content: "\1F0DF";
  color: blue;
  font-size: 200px;
  display: block;
}

.overlay-wrapper {
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 100;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
} */

.box {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
}
.navigation {
    position: fixed;
    /* position: relative; */
    top: 0px;
    left: 0px;
    width: 100%;
    height: 60px;
    overflow: visible;
    background-color: rgba(255,255,255,1);
    border-bottom: 1px solid rgba(0,0,0,1);
    box-sizing: border-box;
    font-family: inherit;
    line-height: inherit;
    z-index: 1000;
}

.navigation nav {
    margin: 0;
}

.navigation .header {
    text-align: left;
    position: relative;
    display: block;
    width: 100%;
    height: inherit;
}

.navigation .header .header-logo {
    height: 40px;
    pointer-events: none;
    margin: 10px;
    position: relative;
    width: auto;
}

@media (prefers-reduced-motion: no-preference) {
    .navigation .header .header-logo {
        animation: header-logo-spin infinite 20s linear;
    }
}

@keyframes header-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.navigation .header .header-menu {
    position: absolute;
    right: 10px;
    width: auto;
    height: auto;
    margin: 10px 0;
    border: 1px solid rgba(181,181,181,1);
}

.navigation .header .header-menu.open {
    background-color: rgba(255,255,255,1);
}

.navigation .header .header-menu .material-symbols-rounded {
    color: rgba(181,181,181,1);
    line-height: 30px;
}

.sub-navigation {
    display: none;
    position: absolute;
    height: auto;
    width: 100%;
    font-family: inherit;
    line-height: inherit;
    background-color: rgba(255,255,255,1);
    overflow-y: auto;
}

.sub-navigation.show {
    display: block;
    box-shadow: 0px 5px 5px rgba(68,68,68,0.6);
}

.sub-navigation ul {
    position: relative;
    display: block;
    font-family: inherit;
    line-height: inherit;
    list-style-type: none;
    margin: 0;
    padding: 0px;
}

.sub-navigation li {
    text-align: left;
    width: 100%;
    position: relative;
    display: block;
    font-family: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0px;
    border-bottom: 0px solid rgba(120,120,120,1);
}

.sub-navigation li.divider {
    border-bottom: 2px solid rgba(0,0,0,1);
}

.sub-navigation li a {
    position: relative;
    padding: 20px;
    display: block;
    font-family: inherit;
    line-height: inherit;
    color: rgba(0,0,0,1);
    text-decoration: none;
}

.sub-navigation li a:hover,
.sub-navigation li a:active {
    color: rgba(0,0,0,1);
}
footer {
    background-color: rgba(51,51,51,1);
    color: rgba(255,255,255,1);
    padding: 20px;
    text-align: center;
    font-size: 14px;
}

footer a {
    color: rgba(255,255,255,1);
    text-decoration: none;
}
.process-message-modal {
    font-family: inherit;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.process-message-modal .process-message-content-wrapper {
    font-family: inherit;
    background-color: rgba(255,255,255,0);
    padding: 2em;
}

.process-message-modal .process-message-content-wrapper p {
    font-family: inherit;
    color: rgba(255,255,255,1);
    font-weight: 700;
}
h1 {
    margin-top: 0;
}

.page {
    min-height: 100vh;
}
