.confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirmation-modal .content-wrapper {
    background-color: rgba(255,255,255,1.0);
    padding: 2em;
    text-align: center;
}

.confirmation-modal .content-wrapper button {
    font-family: inherit;
    position: relative;
    padding: 10px;
    margin: auto 10px;
    width: inherit;
    height: 100%;
    outline: none;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    -webkit-appearance: none;
}