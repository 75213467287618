.magic-card {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 70px 10px 10px;
}

.magic-card-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}

.magic-card-page h1 {
    text-align: center;
    margin: 10% auto;
}

.magic-card-page span {
    display: block;
    font-size: 0.6em;
    text-align: center;
}


@media (min-width: 1024px) {
    .magic-card-page h1 {
        text-align: center;
        margin: 20px auto;
    }
}