/*BEGIN ITEM CONTENT*/

.item-content {
    font-size: 13px;
    color: #fff;
    display: block;
    height: auto;
    position: relative;
    overflow: hidden;
  }
  
  .item-content .item-info {
    display: flex;
    position: relative;
    margin-bottom: 10px;
  }
  
  .item-content .item-info > div {
    line-height: 24px;
    margin-right: 5px;
  }
  
  .item-content .item-info .item-username {
    display: inline-flex;
    position: relative;
  }
  
  .item-content .item-info .item-avatar {
    display: inline-flex;
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: gray;
  }
  
  .item-content .item-info .item-follow {
    display: inline-flex;
    position: relative;
  }
  
  .item-content .item-description {
    display: block;
    min-height: 150px;
    height: auto;
    overflow: hidden;
  }
  /*END ITEM CONTENT*/