html  {
    background: #ffffff;
    scroll-snap-type: y mandatory;
}

body {
    font-family: 'Roboto', sans-serif;
    position: relative;
    margin: 0;
    font-size: 16px;
    /* color: #fff;
    text-shadow: 1px 1px #000; */
}

.grid-container {
    position: relative;
    display: grid;
    grid-template-columns: auto;
    gap: 0;
    background-color: #ffffff;
    height: calc(100vh - 65px);
    padding: 0;
    margin: 0;
    scroll-snap-align: start;
    overflow: hidden;
    z-index: 1;
  }
  
  .grid-container > div.grid-item {
    position: relative;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 0;
    font-size: 30px;
    background: linear-gradient(45deg, #808080, #a0a0a0, #d0d0d0); /* dark gray to medium gray to light gray */
    background-size: 200% 200%;
    animation: gradientAnimation 3s infinite;
  }

  .grid-container:last-child {
    margin-bottom: 65px;
  }
  
  .grid-item.right-box-2x4 {
    grid-area: 1 / 2 / 3 / 4;
  }

  .grid-item.left-box-2x4 {
    grid-area: 4 / 1 / 6 / 3;
  }

  .grid-container > div.grid-item-top {
    position: absolute;
    display: block;
    top: 0px;
    height: 65px;
    width: 100%;
    background: transparent;
    z-index: 300;
  }

  .grid-container > div.grid-item-left {
    position: absolute;
    display: block;
    padding: 0 0 0 15px;
    left: 0px;
    bottom: 100px;
    width: 110px;
    height: 140px;
    background: transparent;
    z-index: 200;
  }

  .grid-container > div.grid-item-right {
    position: absolute;
    display: block;
    right: 0px;
    bottom: 65px;
    width: 65px;
    background: transparent;
    z-index: 200;
  }

  .grid-container > div.grid-item-bottom {
    position: absolute;
    display: block;
    bottom: 0px;
    height: 65px;
    width: auto;
    padding: 15px;
    background: transparent;
    z-index: 100;
  }

  .grid-container > div.grid-item {
    position: absolute;
    display: block;
    top: 0px;
    bottom: 0px;
    width: 100%;
    background-color: #ffffff;
    z-index: 1;
  }

  @keyframes gradientAnimation {
    0% {
        background-position: 100% 0%;
    }
    50% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 100% 0%;
    }
  }