.search-form {
    position: relative;
    padding: 0px;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: Verdana, sans-serif;
}

.search-form {
    display: block;
    width: 100%;
    height: 100%;
}

.search-form .input-wrapper {
    display: inline-block;
    width: 100%;
    height: 100%;
    margin: 10px auto 0px;
}

.search-form .input-wrapper input {
    font-family: inherit;
    position: relative;
    padding: 10px;
    margin: 0;
    width: inherit;
    height: 100%;
    outline: none;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    -webkit-appearance: none;
}

.search-form .input-wrapper input.error {
    border-color: rgba(255, 0, 0, 1);
}

.search-form .input-wrapper label {
    display: none;
    /* display: inline-block; */
    position: absolute;
    top: -18px;
    left: 10px;
    padding: 10px 10px 0;
    margin: 0;
    width: auto;
    height: auto;
    font-size: 14px;
    background-color: rgba(255,255,255,1);
}

