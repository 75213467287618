/*BEGIN REACTION CAMERA BUTTON*/
.reaction-camera-view {
  display: block;
  height: inherit;
  width: inherit;
  padding: 0;
  margin: 0;
  background: rgba(255, 255, 255, 0.2);
  border-style: dashed;
  border-width: 2px;
  border-radius: 5px;
  text-align: center;
  padding: 0px;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  text-shadow: none;
  position: relative;
}

.reaction-camera-view .recording-wrapper-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 35px;
  width: 25px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  overflow: hidden;
  z-index: 5;
}

.reaction-camera-view .recording-wrapper-icon .recording-icon {
  height: 25px;
  width: 25px;
  margin: auto;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.5);
}

.reaction-camera-view .recording-wrapper-icon .recording-text {
  font-size: 10px;
  color: rgba(255, 255, 255, 1);
}
/*END REACTION CAMERA BUTTON*/