/*BEGIN ITEM ACTION*/
.item-action {
    position: relative;
    display: block;
    font-size: 13px;
    color: #ffffff;
    height: 50px;
    width: 50px;
    margin: 30px auto;
  }
  
  .item-icon {
    height: 38px;
    width: 50px;
    background: gray;
  }
  
  .item-text {
    height: 13px;
    width: 50px;
    text-align: center;
  }
  /*END ITEM ACTION*/