.verification-modal {
    font-family: inherit;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.verification-modal .verification-content-wrapper {
    font-family: inherit;
    width: 70vw;
    padding: 20px;
    background-color: rgba(255,255,255,1);
    text-align: center;
}

.verification-modal .verification-content-wrapper p {
    font-family: inherit;
    font-weight: 700;
    margin: 0;
}

.verification-modal .verification-content-wrapper span {
    font-family: inherit;
    font-weight: 700;
    font-size: 12px;
}

.verification-modal .verification-content-wrapper .code-input {
    display: flex;
    width: inherit;
    overflow: hidden;
    height: 50px;
    margin: 10px auto;
}

.verification-modal .input-wrapper {
    display: inline-flex;
    width: 100%;
}

.verification-modal .input-wrapper input,
.verification-modal .input-wrapper button {
    font-family: inherit;
    position: relative;
    padding: 5px 10px;
    margin: 2px;
    width: auto;
    height: auto;
    outline: none;
    border: 1px solid #ccc;
    box-sizing: border-box;
    -webkit-appearance: none;
    text-align: center;
    text-transform: uppercase;
}

.verification-modal input[type="button"],
.verification-modal input[type="submit"] {
    margin: auto 10px;
    width: auto;
    padding: 10px 20px;
    display: inline-block;
}

.verification-modal .input-wrapper input.error {
    border-color: rgba(255, 0, 0, 1);
}

@media screen and (min-width: 1024px) {
    .verification-modal .verification-content-wrapper {
        font-family: inherit;
        width: 30vw;
        padding: 20px;
        background-color: rgba(255,255,255,1);
        text-align: center;
    }
}
  