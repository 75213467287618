/*BEGIN ITEM VIDEO*/

.grid-item .item-video {
  width: 100%;
  height: 100%;
  position: relative;
}

/*END ITEM VIDEO*/

/*BEGIN VIDEO AND IMAGE VIDEO*/

.video {
  position: absolute;
  width: 100vw;
  height: auto;
  object-fit: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,1);
  pointer-events: none;
  z-index: 1;

  /* min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; */
}

.video-image {
  position: absolute;
  width: 100vw;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,1);
  pointer-events: none;
  z-index: 1;
}

.video-image-blur {
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  filter: blur(8px);
  -webkit-filter: blur(8px);  
  background-color: rgba(0,0,0,1);
  pointer-events: none;
  z-index: 0;
}
/*END VIDEO AND IMAGE VIDEO*/