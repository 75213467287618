.lists {
    position: relative;
    display: block;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.lists ul {
    width: 100%;
    display: block;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.lists li {
    width: 100%;
    display: inline-block;
    padding: 0px;
    margin: 0px;
    border-top: 1px solid rgba(181,181,181,1);
}