.account-info {
    font-family: inherit;
    position: relative;
    width: 100%;
    height: auto;
    display: block;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    text-align: left;
}

.account-info ul {
    width: 100%;
    display: block;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.account-info li {
    width: 100%;
    display: inline-block;
    padding: 0px;
    margin: 0px;
}

.account-info li.label {
    font-size: 12px;
    font-weight: 900;
    margin-bottom: 10px;
}

.account-info li.value {
    font-size: 14px;
    font-weight: 500;
}

.account-info li:nth-child(even) {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(181,181,181,1);
}

.account-info .input-action-wrapper {
    text-align: center;
    position: relative;    
    display: block;
    margin: 10px auto;
    background: transparent;
    width: 100%;
    height: auto;
    z-index: 1;
}

.account-info input,
.account-info button,
.account-info select,
.account-info option {
    font-family: inherit;
    position: relative;
    padding: 10px;
    margin: 0;
    width: inherit;
    height: 100%;
    outline: none;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    -webkit-appearance: none;
}

.account-info input[type="button"],
.account-info input[type="submit"] {
    margin: auto 10px;
    border-radius: 20px;
    width: auto;
    padding: 10px 20px;
    display: inline-block;
}

.account-info input.error {
    border-color: rgba(255, 0, 0, 1);
}