.card.heart:after, 
.card.diamond:after {
  color: red;
}

.card.spade:after, 
.card.club:after {
  color: black;
}

.card.heart:after, 
.card.diamond:after,
.card.spade:after, 
.card.club:after {
  font-size: 200px;
}

/* cards  hearts */

.heart-a:after {
  content: "\1F0B1";
}

.heart-2:after {
  content: "\1F0B2";
}

.heart-3:after {
  content: "\1F0B3";
}

.heart-4:after {
  content: "\1F0B4";
}

.heart-5:after {
  content: "\1F0B5";
}

.heart-6:after {
  content: "\1F0B6";
}

.heart-7:after {
  content: "\1F0B7";
}

.heart-8:after {
  content: "\1F0B8";
}

.heart-9:after {
  content: "\1F0B9";
}

.heart-10:after {
  content: "\1F0BA";
}

.heart-j:after {
  content: "\1F0BB";
}

.heart-q:after {
  content: "\1F0BD";
}

.heart-k:after {
  content: "\1F0BE";
}

/* end cards hearts */

/* cards diamonds */

.diamond-a:after {
  content: "\1F0C1";
}

.diamond-2:after {
  content: "\1F0C2";
}

.diamond-3:after {
  content: "\1F0C3";
}

.diamond-4:after {
  content: "\1F0C4";
}

.diamond-5:after {
  content: "\1F0C5";
}

.diamond-6:after {
  content: "\1F0C6";
}

.diamond-7:after {
  content: "\1F0C7";
}

.diamond-8:after {
  content: "\1F0C8";
}

.diamond-9:after {
  content: "\1F0C9";
}

.diamond-10:after {
  content: "\1F0CA";
}

.diamond-j:after {
  content: "\1F0CB";
}

.diamond-q:after {
  content: "\1F0CD";
}

.diamond-k:after {
  content: "\1F0CE";
}

/* end cards diamonds */

/* cards spades */

.spade-a:after {
  content: "\1F0A1";
}

.spade-2:after {
  content: "\1F0A2";
}

.spade-3:after {
  content: "\1F0A3";
}

.spade-4:after {
  content: "\1F0A4";
}

.spade-5:after {
  content: "\1F0A5";
}

.spade-6:after {
  content: "\1F0A6";
}

.spade-7:after {
  content: "\1F0A7";
}

.spade-8:after {
  content: "\1F0A8";
}

.spade-9:after {
  content: "\1F0A9";
}

.spade-10:after {
  content: "\1F0AA";
}

.spade-j:after {
  content: "\1F0AB";
}

.spade-q:after {
  content: "\1F0AD";
}

.spade-k:after {
  content: "\1F0AE";
}

/* end cards spades */

/* cards clubs */

.club-a:after {
  content: "\1F0D1";
}

.club-2:after {
  content: "\1F0D2";
}

.club-3:after {
  content: "\1F0D3";
}

.club-4:after {
  content: "\1F0D4";
}

.club-5:after {
  content: "\1F0D5";
}

.club-6:after {
  content: "\1F0D6";
}

.club-7:after {
  content: "\1F0D7";
}

.club-8:after {
  content: "\1F0D8";
}

.club-9:after {
  content: "\1F0D9";
}

.club-10:after {
  content: "\1F0DA";
}

.club-j:after {
  content: "\1F0DB";
}

.club-q:after {
  content: "\1F0DD";
}

.club-k:after {
  content: "\1F0DE";
}

/* end cards clubs */