.navigation {
    position: fixed;
    /* position: relative; */
    top: 0px;
    left: 0px;
    width: 100%;
    height: 60px;
    overflow: visible;
    background-color: rgba(255,255,255,1);
    border-bottom: 1px solid rgba(0,0,0,1);
    box-sizing: border-box;
    font-family: inherit;
    line-height: inherit;
    z-index: 1000;
}

.navigation nav {
    margin: 0;
}

.navigation .header {
    text-align: left;
    position: relative;
    display: block;
    width: 100%;
    height: inherit;
}

.navigation .header .header-logo {
    height: 40px;
    pointer-events: none;
    margin: 10px;
    position: relative;
    width: auto;
}

@media (prefers-reduced-motion: no-preference) {
    .navigation .header .header-logo {
        animation: header-logo-spin infinite 20s linear;
    }
}

@keyframes header-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.navigation .header .header-menu {
    position: absolute;
    right: 10px;
    width: auto;
    height: auto;
    margin: 10px 0;
    border: 1px solid rgba(181,181,181,1);
}

.navigation .header .header-menu.open {
    background-color: rgba(255,255,255,1);
}

.navigation .header .header-menu .material-symbols-rounded {
    color: rgba(181,181,181,1);
    line-height: 30px;
}

.sub-navigation {
    display: none;
    position: absolute;
    height: auto;
    width: 100%;
    font-family: inherit;
    line-height: inherit;
    background-color: rgba(255,255,255,1);
    overflow-y: auto;
}

.sub-navigation.show {
    display: block;
    box-shadow: 0px 5px 5px rgba(68,68,68,0.6);
}

.sub-navigation ul {
    position: relative;
    display: block;
    font-family: inherit;
    line-height: inherit;
    list-style-type: none;
    margin: 0;
    padding: 0px;
}

.sub-navigation li {
    text-align: left;
    width: 100%;
    position: relative;
    display: block;
    font-family: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0px;
    border-bottom: 0px solid rgba(120,120,120,1);
}

.sub-navigation li.divider {
    border-bottom: 2px solid rgba(0,0,0,1);
}

.sub-navigation li a {
    position: relative;
    padding: 20px;
    display: block;
    font-family: inherit;
    line-height: inherit;
    color: rgba(0,0,0,1);
    text-decoration: none;
}

.sub-navigation li a:hover,
.sub-navigation li a:active {
    color: rgba(0,0,0,1);
}