/*BEGIN REACTION CAMERA BUTTON*/
.reaction-camera-button {
  display: block;
  height: inherit;
  width: inherit;
  padding: 0;
  margin: 0;
  background: rgba(255, 255, 255, 0.2);
  border-style: dashed;
  border-width: 2px;
  border-radius: 5px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  text-shadow: none;
}

.reaction-camera-button .camera-icon {
  height: 40px;
  width: 40px;
  margin: 10px auto;
  background-color: rgba(255, 255, 255, 0.5);
}
/*END REACTION CAMERA BUTTON*/