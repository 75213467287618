.videoContainer {
  position: relative; /* Change this from 'fixed' */
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
  overflow: hidden;
}

.responsiveVideo {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute; /* Change this from 'fixed' */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.videoImage {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  z-index: 1;
}

/* .videoImage.facingMode {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
} */

.videoImageBackground { 
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);  
  background-color: rgba(0,0,0,1);
  pointer-events: none;
  z-index: 0;
}

/* .videoImageBackground.facingMode {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
} */