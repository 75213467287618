/*BEGIN ITEM BUBBLE*/
.item-bubbles {
  padding: 15px 0 0;
  height: 140px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.item-bubble-box {
  display: inline-block;
  position: relative;
  width: auto;
  height: inherit;
  margin: auto 5px;
}

.item-bubble-box > div {
  float: left;
  clear: both;
  margin-bottom: 10px;
}

.item-bubble {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  line-height: 100px;
  color: #ffffff;
  border-style: dashed;
  border-width: 2px;
  border-color: #ffffff;
  margin: auto;
  text-align: center;
  background-color: gray;
}

.item-bubble.item-bubble-30 {
  font-size: 6px;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  line-height: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border-width: 1px;
}

.item-bubble.item-bubble-45 {
  font-size: 9px;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  line-height: 45px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border-width: 1px;
}

.item-bubble.item-bubble-50 {
  font-size: 11px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  line-height: 50px;
  margin: 0 5px 10px;
  border-width: 1px;
}

.item-bubble.item-bubble-60 {
  font-size: 12px;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  line-height: 60px;
}

.item-bubble.item-bubble-100 {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  line-height: 100px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.item-bubble-live {
  display: inline;
  background-color: #B8FF3C;
  color: #000;
  text-shadow: none;
  border-radius: 5px;
  border: 1px solid #ffffff;
  
}

.item-bubble.item-bubble-30 .item-bubble-live {
  position: absolute;
  line-height: 10px;
  transform: translate(-53%, -50%);
  bottom: -15px;
  padding: 1px 3px;
}

.item-bubble.item-bubble-45 .item-bubble-live {
  position: absolute;
  line-height: 10px;
  transform: translate(-53%, -50%);
  bottom: -18px;
  padding: 3px;
} 

.item-bubble.item-bubble-50 .item-bubble-live {
  position: absolute;
  line-height: 10px;
  transform: translate(-125%, -50%);
  bottom: -5px;
  padding: 4px;
} 

.item-bubble.item-bubble-60 .item-bubble-live {
  position: absolute;
  line-height: 10px;
  transform: translate(-125%, -50%);
  bottom: -5px;
  padding: 5px;
}

.item-bubble.item-bubble-50 .item-bubble-live.top {
  position: absolute;
  line-height: 10px;
  transform: translate(-125%, -50%);
  bottom: 70px;
  padding: 4px;
} 

.item-bubble.item-bubble-60 .item-bubble-live.top {
  position: absolute;
  line-height: 10px;
  transform: translate(-125%, -50%);
  bottom: 58px;
  padding: 5px;
}

.item-bubble.item-bubble-100 .item-bubble-live {
  position: absolute;
  line-height: 10px;
  transform: translate(-53%, -50%);
  bottom: -28px;
  padding: 9px;
} 
/*END ITEM BUBBLE*/

/*BEGIN VIDEO AND IMAGE BUBBLE*/

.bubble-image,
.bubble-video {
  position: absolute;
  width: inherit;
  height: inherit;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  border-radius: inherit;
}

.item-bubble.item-bubble-50 .bubble-image,
.item-bubble.item-bubble-60 .bubble-image, 
.item-bubble.item-bubble-50 .bubble-video,
.item-bubble.item-bubble-60 .bubble-video {
  position: relative;
}
/*END VIDEO AND IMAGE BUBBLE*/