footer {
    background-color: rgba(51,51,51,1);
    color: rgba(255,255,255,1);
    padding: 20px;
    text-align: center;
    font-size: 14px;
}

footer a {
    color: rgba(255,255,255,1);
    text-decoration: none;
}