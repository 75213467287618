.camera-wrapper {
    /* position: relative; */
    display: table;
    height: inherit;
    z-index: 1
}

/* .canvas-video {
    max-width: 100%;
    height: inherit;
    display: table-cell;
    vertical-align: middle;
    position: absolute;
    z-index: 3
}

.canvas-video.facing-mode {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); 
    -moz-transform: rotateY(180deg); 
} */

.camera-video {
    max-width: 100%;
    height: inherit;
    display: table-cell;
    vertical-align: middle;
    position: absolute;
    z-index: 2 
}

.camera-video.facing-mode {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); 
    -moz-transform: rotateY(180deg); 
}