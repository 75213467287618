.pagination {
    font-family: inherit;
    position: relative;
    display: block;
    margin: 0;
    padding: 0px;
    text-align: center;
}

.pagination-header {
    color: rgba(50, 50, 50, 1);
    font-size: 14px;
    position: relative; 
    margin: 20px 20px;
}

.pagination-text {
    color: rgba(50, 50, 50, 1);
    font-size: 12px;
    position: relative; 
}

.pagination-item-link {
    /* color: rgba(50, 50, 50, 1);
    font-size: 12px;
    font-family: inherit;
    position: relative;
    text-decoration: none;
    display: inline-block;
    margin: 10px 3px; */

    color: rgba(50, 50, 50, 1);
    font-size: 12px;
    font-family: inherit;
    text-decoration: none;
    display: inline-block;
    margin: 10px 0px;
    border: 1px solid rgba(181,181,181,1);
    padding: 12px;
    margin-left: -1px;
}

.pagination-item-link.back, 
.pagination-item-link.forward {
    /* font-size: 12px;
    margin: 10px 3px; */

    font-size: 12px;
    margin: 10px 0px;
}

.pagination-item-link.forward {
    margin-left: -1px;
}

.pagination-item-link.active {
    font-weight: bold;
}

.pagination-item-link:active,
.pagination-item-link:focus
.pagination-item-link:hover {
    color: rgba(50, 50, 50, 1);
}