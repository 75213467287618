.search-page {
    font-family: Verdana, sans-serif;
    position: relative;
    width: auto;
    height: auto;
    padding: 70px 10px 10px;
}

.search-wrapper,
.search-lists-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}