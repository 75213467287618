.question-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  height: auto;
}

.question-card {
  padding: 10%;
  text-align: center;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.question-card h2 {
  margin-bottom: 20px;
}

.box-action > div.card {
  margin: 10px;
  display: inline-block;
  cursor: pointer;
}

.box-action > div.card.heart:after  {
  content: "\2665";
  color: red;
}

.box-action > div.card.spade:after {
  content: "\2660";
  color: black;
}

.box-action > div.card.diamond:after {
  content: "\2666";
  color: red;
}

.box-action > div.card.club:after {
  content: "\2663";
  color: black;
}

.box-action > div.card:after {
  font-size: 100px;
}

.question-card > div.card.joker:before {
  content: "\1F0DF";
  color: blue;
  font-size: 150px;
  display: block;
}
 
/* .overlay-wrapper div.card.joker:before {
  content: "\1F0DF";
  color: blue;
  font-size: 200px;
  display: block;
}

.overlay-wrapper {
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 100;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
} */

.box {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
}