.list-item {
    font-family: inherit;
    text-align: left;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 20px 0px;
}

.list-item .image-wrapper {
    background-color: rgba(181,181,181,1);
    position: absolute;
    margin: 0 20px 20px 0;
    height: 60px;
    width: 60px;
    overflow: hidden;
}

.list-item img {
    position: relative;
    display: inline-block;
    margin: 0;
    width: inherit;
    height: auto;
}

.list-item .header-wrapper {
    position: relative;
    margin: 0 0 20px 80px;
    height: 60px;
    overflow: hidden;
    width: auto;
}

.list-item .header-wrapper .item-title {
    font-family: inherit;
    margin: 0px;
    font-size: 1.20em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-item .header-wrapper .item-sub-text {
    color: rgba(111, 111, 111, 1);
    font-family: inherit;
    font-size: 14px;
    line-height: 20px;
    font-style: italic;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-item p {
    font-family: inherit;
    line-height: 24px;
    padding: 0px;
    margin-bottom: 20px;
    clear: both;
}

.list-item .item-link {
    color: rgba(1,118,152,1);
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    position: relative;
    font-weight: bold;
    float: right;
    text-decoration: none;
}