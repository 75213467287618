.card-deck {
    cursor: pointer;
}

.card-deck:after {
    content: "\1F0A0";
    font-size: 200px;
}

.card-deck.red:after {
    color: red;
}

.card-deck.blue:after {
    color: blue;
}

.card-deck.black:after {
    color: black;
}